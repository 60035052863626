import React, {useEffect, useRef, useState} from "react";
import "./App.css";
import * as emailjs from "emailjs-com";

function App() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        reason: "",
        source: "",
        contactMethod: "",
    });

    const [errors, setErrors] = useState({});
    const [theme, setTheme] = useState("dark");
    const [showPopup, setShowPopup] = useState(false);
    const dienstenRef = useRef(null);
    const contactRef = useRef(null);
    const topRef = useRef(null);

    // Apply the theme to the body element
    useEffect(() => {
        document.body.className = theme;
    }, [theme]);

    // Detect system theme on initial load and listen for changes
    useEffect(() => {
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        const applySystemTheme = () => setTheme(mediaQuery.matches ? "dark" : "light");

        applySystemTheme(); // Apply initial theme
        mediaQuery.addEventListener("change", applySystemTheme); // Listen for changes

        return () => {
            mediaQuery.removeEventListener("change", applySystemTheme); // Clean up on unmount
        };
    }, []);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
    };

    const scrollToDiensten = () => dienstenRef.current.scrollIntoView({behavior: "smooth"});
    const scrollToContact = () => contactRef.current.scrollIntoView({behavior: "smooth"});

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});

        // Clear the error message as the user types
        setErrors({...errors, [name]: ""});
    };

    const handleBlur = (e) => {
        const {name, value} = e.target;

        // Define field name translations
        const fieldTranslations = {
            firstName: "Voornaam",
            lastName: "Achternaam",
            email: "E-mailadres",
            phone: "Telefoonnummer",
            reason: "Reden",
            source: "Bron",
            contactMethod: "Contactmethode",
        };

        // Validate on blur
        if (!value.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: `${fieldTranslations[name]} is verplicht.`,
            }));
        } else if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: "Ongeldig e-mailadres.",
            }));
        } else if (
            name === "phone" &&
            !/^(?:\+32|0032|0{1,2}32|0)?\s?(4\d{2})\s?\d{2}\s?\d{2}\s?\d{2}$/.test(value.replace(/\s/g, ""))
        ) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: "Ongeldig telefoonnummer.",
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Define field name translations
        const fieldTranslations = {
            firstName: "Voornaam",
            lastName: "Achternaam",
            email: "E-mailadres",
            phone: "Telefoonnummer",
            reason: "Reden",
            source: "Bron",
            contactMethod: "Contactmethode",
        };

        const newErrors = {};
        Object.entries(formData).forEach(([key, value]) => {
            if (!value.trim()) {
                newErrors[key] = `${fieldTranslations[key]} is verplicht.`;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        emailjs
            .send(
                "klikvooruit-p001",
                "klikvooruit-p001-temp",
                formData,
                "zYVX1HnJt1nYOMEfD"
            )
            .then(() => setShowPopup(true))
            .catch(() =>
                alert("Er is een fout opgetreden bij het verzenden. Probeer het opnieuw.")
            );

        setTimeout(() => {
            setShowPopup(false);
        }, 3000);

        setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            reason: "",
            source: "",
            contactMethod: "",
        });
        setErrors({});
    };

    return (<div className="container">
        <div ref={topRef}></div>
        {/* Ref for scrolling back to the top */}
        {showPopup && <div className="overlay"></div>} {/* Overlay */}
        {showPopup && (<div className="popup">
            <p>Bedankt voor uw bericht! We nemen snel contact met u op.</p>
        </div>)}
        <header className="hero">
            <div className="hero-content">
                {/* Add the logo at the top */}
                <img
                    src={`${process.env.PUBLIC_URL}/images/logo.png`}
                    alt="KlikVooruit Logo"
                    className="logo"
                />
                <h1>Welkom bij KlikVooruit</h1>
                <p>
                    Leer essentiële digitale vaardigheden en blijf verbonden met de
                    wereld om je heen. Voor iedereen die klaar is om digitaal sterker te worden!
                </p>
                <div className="button-group">
                    <button className="cta-button" onClick={scrollToDiensten}>
                        Ontdek Onze Diensten
                    </button>
                    <button className="cta-button" onClick={scrollToContact}>
                        Contacteer Ons Nu
                    </button>
                </div>
            </div>
        </header>
        <main>

            <section className="services" ref={dienstenRef}>
                <h2>Onze Diensten</h2>
                <div className="service-grid">
                    <div className="service-card">
                        <h3>🛠️ Basisvaardigheden IT</h3>
                        <p>Leer omgaan met computers, tablets, smartphones en nog veel meer.</p>
                    </div>
                    <div className="service-card">
                        <h3>📸 Sociale Media</h3>
                        <p>Blijf in contact met familie en vrienden via WhatsApp, Facebook en meer.</p>
                    </div>
                    <div className="service-card">
                        <h3>🔐 Online Veiligheid</h3>
                        <p>Bescherm uzelf tegen online gevaren zoals phishing en fraude.</p>
                    </div>
                    <div className="service-card">
                        <h3>🛒 Online Winkelen</h3>
                        <p>Ontdek hoe u veilig kunt winkelen en bankieren online.</p>
                    </div>
                </div>
            </section>

            <div className="graphic-container">
                <img src="/images/image.png" alt="Fun graphic" className="graphic-image"/>
            </div>

            <section className="why-us">
                <h2>Waarom kiezen voor ons?</h2>
                <div className="reasons-grid">
                    <div className="reason-card">
                        <h3>🎓 Ervaren jongeren met echte ervaring</h3>
                        <p>
                            Onze jonge trainers hebben een frisse blik en ervaring in het
                            werken met digitale tools en platforms.
                        </p>
                    </div>
                    <div className="reason-card">
                        <h3>📐 Op maat van de klant</h3>
                        <p>
                            Wij bieden begeleiding volledig afgestemd op uw
                            behoeften en tempo.
                        </p>
                    </div>
                    <div className="reason-card">
                        <h3>🤝 Persoonlijke begeleiding</h3>
                        <p>
                            Geen vraag is te simpel – wij zorgen voor persoonlijke
                            ondersteuning die u het vertrouwen geeft om zelfstandig te
                            werken.
                        </p>
                    </div>
                    <div className="reason-card">
                        <h3>💻 Praktijkgerichte aanpak</h3>
                        <p>
                            Leer direct door te doen! Praktische oefeningen helpen u de
                            vaardigheden meteen toe te passen.
                        </p>
                    </div>
                    <div className="reason-card">
                        <h3>⏱ Flexibele planning</h3>
                        <p>
                            Wij passen ons aan uw agenda aan, zodat leren altijd op uw
                            tijd gebeurt.
                        </p>
                    </div>
                    <div className="reason-card">
                        <h3>🌍 Verbinding met de moderne wereld</h3>
                        <p>
                            Beheers technologie om verbonden te blijven met familie, vrienden,
                            en de wereld om u heen.
                        </p>
                    </div>
                </div>
            </section>

            <section className="pricing-section">
                <h2>Maatwerk Prijsstructuur</h2>
                <div className="pricing-card-container">
                    <div className="pricing-card">
                        <p>Onze prijzen worden afgestemd op:</p>
                        <ul>
                            <li>Complexiteit van de oplossing of opleiding</li>
                            <li>Duur van de lessen of implementatie</li>
                        </ul>
                    </div>
                    <button className="cta-button" onClick={scrollToContact}>Neem contact met ons op voor meer
                        informatie
                    </button>
                </div>
            </section>


            <section className="contact" ref={contactRef}>
                <h2>Neem contact op</h2>
                <p>Heeft u vragen of wilt u lessen boeken? Vul het onderstaande formulier in.</p>
                <p>We nemen zo snel als mogelijk contact met u op.</p>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Voornaam</label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Uw voornaam"
                        />
                        {errors.firstName && (
                            <p className="error-message">{errors.firstName}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label>Achternaam</label>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Uw achternaam"
                        />
                        {errors.lastName && (
                            <p className="error-message">{errors.lastName}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label>E-mail</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Uw e-mailadres"
                        />
                        {errors.email && (
                            <p className="error-message">{errors.email}</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label>Telefoonnummer</label>
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Uw telefoonnummer"
                        />
                        {errors.phone && (
                            <p className="error-message">{errors.phone}</p>
                        )}
                    </div>
                    <div className="form-group form-group-radio">
                        <div className="radio-title">Hoe wilt u gecontacteerd worden?</div>
                        <div className="radio-options">
                            <div className="radio-option">
                                <input
                                    type="radio"
                                    id="contact-email"
                                    name="contactMethod"
                                    value="email"
                                    onChange={handleChange}
                                    checked={formData.contactMethod === "email"}
                                    required
                                />
                                <label htmlFor="contact-email">E-mail</label>
                            </div>
                            <div className="radio-option">
                                <input
                                    type="radio"
                                    id="contact-phone"
                                    name="contactMethod"
                                    value="phone"
                                    onChange={handleChange}
                                    checked={formData.contactMethod === "phone"}
                                    required
                                />
                                <label htmlFor="contact-phone">Telefoon</label>
                            </div>
                        </div>
                    </div>


                    <div className="form-group">
                        <label>Hoe kunnen we u van dienst zijn?</label>
                        <textarea
                            name="reason"
                            value={formData.reason}
                            onChange={handleChange}
                            placeholder="Wat zou u willen leren of graag hulp bij hebben?"
                            required
                        ></textarea>
                    </div>
                    <div className="form-group form-group-radio">
                        <div className="radio-title">Hoe heeft u ons gevonden?</div>
                        <div className="radio-options">
                            <div className="radio-option">
                                <input
                                    type="radio"
                                    id="source-google"
                                    name="source"
                                    value="google"
                                    onChange={handleChange}
                                    checked={formData.source === "google"}
                                    required
                                />
                                <label htmlFor="source-google">Google</label>
                            </div>
                            <div className="radio-option">
                                <input
                                    type="radio"
                                    id="source-vrienden"
                                    name="source"
                                    value="vrienden"
                                    onChange={handleChange}
                                    checked={formData.source === "vrienden"}
                                    required
                                />
                                <label htmlFor="source-vrienden">Via vrienden</label>
                            </div>
                            <div className="radio-option">
                                <input
                                    type="radio"
                                    id="source-socialmedia"
                                    name="source"
                                    value="socialmedia"
                                    onChange={handleChange}
                                    checked={formData.source === "socialmedia"}
                                    required
                                />
                                <label htmlFor="source-socialmedia">Sociale media</label>
                            </div>
                            <div className="radio-option">
                                <input
                                    type="radio"
                                    id="source-advertentie"
                                    name="source"
                                    value="advertentie"
                                    onChange={handleChange}
                                    checked={formData.source === "advertentie"}
                                    required
                                />
                                <label htmlFor="source-advertentie">Advertentie</label>
                            </div>
                            <div className="radio-option">
                                <input
                                    type="radio"
                                    id="source-anders"
                                    name="source"
                                    value="anders"
                                    onChange={handleChange}
                                    checked={formData.source === "anders"}
                                    required
                                />
                                <label htmlFor="source-anders">Anders</label>
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="cta-button">
                        Verstuur
                    </button>
                </form>
            </section>
        </main>
        <footer>
            <p>© 2024 KlikVooruit. Alle rechten voorbehouden.</p>
            <div className="theme-toggle">
                <span className="icon sun">☀️</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={theme === "dark"}
                        onChange={toggleTheme}
                    />
                    <span className="slider"></span>
                </label>
                <span className="icon moon">🌙</span>
            </div>
        </footer>
    </div>);
}

export default App;
